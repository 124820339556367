<template>
  <a-modal
    title="修改存档版本"
    :width="640"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">

      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="模板">
          <a-select @change="presetSelect" :value="0">
            <a-select-option v-for="item in versionPreset" :key="item.name" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- 检查是否有 id 并且大于0，大于0是修改。其他是新增，新增不显示主键ID -->
        <a-form-item v-show="model && model.playerId > 0" label="玩家ID">
          <a-input v-decorator="['playerId', { initialValue: 0 }]" disabled />
        </a-form-item>
        <a-form-item label="ROM版本">
          <a-input v-decorator="['lastRomVersion', {rules: [{required: true}]}]" placeholder="1.19.06"/>
        </a-form-item>
        <a-form-item label="OPT版本">
          <a-input v-decorator="['lastDataVersion', {rules: [{required: true}]}]" placeholder="1.15.06"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { Mai2GameVersionPreset } from '@/model/mai2const'

// 表单字段
const fields = ['playerId', 'lastRomVersion', 'lastDataVersion']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      versionPreset: Mai2GameVersionPreset
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    presetSelect (ev) {
      console.log(ev)
      const preset = Mai2GameVersionPreset[ev]
      this.form.setFieldsValue(pick(preset, fields))
    }
  }
}
</script>
