<template>
  <page-header-wrapper>
    <a-card :bordered="false" v-if="detail">
      <a-descriptions title="玩家信息">
        <a-descriptions-item label="Access Code">
          <router-link :to="{name: 'Aime List', query: {accessCode: detail.user.accessCode }}">{{ detail.user.accessCode }}</router-link>
        </a-descriptions-item>
        <a-descriptions-item label="会员等级">
          {{ currentMember }} <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              修改会员等级 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-for="item in netMemberType" :key="item.label">
                <a @click="setMember(item.id)">{{ item.label }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-descriptions-item>
        <a-descriptions-item label="用户名">
          {{ detail.user.userName }}
          <a @click="handleEditUsername()">修改</a>
        </a-descriptions-item>
        <a-descriptions-item label="歌曲Rating">{{ detail.user.musicRating }}</a-descriptions-item>
        <a-descriptions-item label="总Rating">{{ detail.user.playerRating }}</a-descriptions-item>
        <a-descriptions-item label="最高Rating">{{ detail.user.highestRating }}</a-descriptions-item>

        <a-descriptions-item label="姓名框ID">
          {{ detail.user.plateId }}
        </a-descriptions-item>
        <a-descriptions-item label="称号ID">
          {{ detail.user.titleId }}
        </a-descriptions-item>
        <a-descriptions-item label="伙伴ID">
          {{ detail.user.partnerId }}
        </a-descriptions-item>
        <a-descriptions-item label="底板ID">
          {{ detail.user.frameId }}
        </a-descriptions-item>
        <a-descriptions-item label="选择地图ID">
          {{ detail.user.selectMapId }}
        </a-descriptions-item>
        <a-descriptions-item label="旅行伙伴">
          {{ detail.user.charaSlot.join(',') }}
          <a @click="handleEditChara()">修改</a>
        </a-descriptions-item>
        <a-descriptions-item label="旅行伙伴锁定">
          {{ detail.user.charaLockSlot.join(',') }}
        </a-descriptions-item>
        <a-descriptions-item label="数据">
          {{ detail.user.contentBit }}
        </a-descriptions-item>
        <a-descriptions-item label="活动公告播放">
          {{ detail.user.eventWatchedDate }}
        </a-descriptions-item>
        <a-descriptions-item label="最后游戏ID">
          {{ detail.user.lastGameId }}
        </a-descriptions-item>
        <a-descriptions-item label="最后ROM版本">
          {{ detail.user.lastRomVersion }}
        </a-descriptions-item>
        <a-descriptions-item label="最后数据版本">
          {{ detail.user.lastDataVersion }}
          <a @click="handleEditVersion()">修改</a>
        </a-descriptions-item>
        <a-descriptions-item label="最后登录时间">
          {{ detail.user.lastLoginDate }}
        </a-descriptions-item>
        <a-descriptions-item label="最后游玩日期">
          {{ detail.user.lastPlayDate }}
        </a-descriptions-item>
        <a-descriptions-item label="最后游玩点数">
          {{ detail.user.lastPlayCredit }}
        </a-descriptions-item>
        <a-descriptions-item label="最后游玩模式">
          {{ detail.user.lastPlayMode }}
        </a-descriptions-item>
        <a-descriptions-item label="最后地点ID">
          {{ detail.user.lastPlaceId }}
        </a-descriptions-item>
        <a-descriptions-item label="最后地点名">
          {{ detail.user.lastPlaceName }}
        </a-descriptions-item>
        <a-descriptions-item label="最后ALLNET ID">
          {{ detail.user.lastAllNetId }}
        </a-descriptions-item>
        <a-descriptions-item label="最后地区ID">
          {{ detail.user.lastRegionId }}
        </a-descriptions-item>
        <a-descriptions-item label="最后地区名">
          {{ detail.user.lastRegionName }}
        </a-descriptions-item>
        <a-descriptions-item label="最后客户端ID">
          {{ detail.user.lastClientId }}
        </a-descriptions-item>
        <a-descriptions-item label="最后地区代码">
          {{ detail.user.lastCountryCode }}
        </a-descriptions-item>
        <a-descriptions-item label="最后使用电子支付">
          {{ detail.user.lastSelectEMoney }}
        </a-descriptions-item>
        <a-descriptions-item label="最后选择票券">
          {{ detail.user.lastSelectTicket }}
        </a-descriptions-item>
        <a-descriptions-item label="最后选择段位">
          {{ detail.user.lastSelectCourse }}
        </a-descriptions-item>
        <a-descriptions-item label="最后段位数">
          {{ detail.user.lastCountCourse }}
        </a-descriptions-item>
        <a-descriptions-item label="初次游戏ID">
          {{ detail.user.firstGameId }}
        </a-descriptions-item>
        <a-descriptions-item label="初次ROM版本">
          {{ detail.user.firstRomVersion }}
        </a-descriptions-item>
        <a-descriptions-item label="初次数据版本">
          {{ detail.user.firstDataVersion }}
        </a-descriptions-item>
        <a-descriptions-item label="初次游玩日期">
          {{ detail.user.firstPlayDate }}
        </a-descriptions-item>
        <a-descriptions-item label="兼容CM版本">
          {{ detail.user.compatibleCmVersion }}
        </a-descriptions-item>
        <a-descriptions-item label="每日奖励日期">
          {{ detail.user.dailyBonusDate }}
          <a @click="handleEditBonus()">修改</a>
        </a-descriptions-item>
        <a-descriptions-item label="每日段位奖励日期">
          {{ detail.user.dailyCourseBonusDate }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px"/>
      <a-descriptions title="游戏数据">
        <a-descriptions-item label="卡片数">
          {{ detail.cardCount }}
          <a-divider type="vertical" />
          <router-link :to="'/game/mai2/player/'+ id+'/card'">管理</router-link>
        </a-descriptions-item>
        <a-descriptions-item label="角色数"><router-link :to="'/game/mai2/player/'+ id+'/character'">{{ detail.characterCount }}</router-link></a-descriptions-item>
        <a-descriptions-item label="物品数"><router-link :to="'/game/mai2/player/'+ id+'/item'">{{ detail.itemCount }}</router-link></a-descriptions-item>
        <a-descriptions-item label="照片上传数"><router-link :to="'/game/mai2/player/'+ id+'/photo'">{{ detail.photoCount }}</router-link></a-descriptions-item>
        <a-descriptions-item label="歌曲成绩数"><router-link :to="'/game/mai2/player/'+ id+'/music'">{{ detail.musicDetailCount }}</router-link></a-descriptions-item>
        <a-descriptions-item label="游玩记录数"><router-link :to="'/game/mai2/player/'+ id+'/playlog'">{{ detail.playlogCount }}</router-link></a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px"/>
      <a-descriptions title="操作">
      </a-descriptions>
      <a-button type="primary" @click="exportPlayer">
        导出数据
      </a-button>
      <a-popconfirm
        :title="'确定删除玩家?' + detail.user.accessCode"
        ok-text="Yes"
        cancel-text="No"
        @cancel="() => {}"
        @confirm="confirmDelete"
      >
        <a-button
          v-if="$auth('ROLE_PERMISSION_MAI2_DELETE_USER')"
          type="danger"
          style="margin-left: 16px">
          删除玩家
        </a-button>
      </a-popconfirm>

      <player-username-form
        ref="usernameForm"
        :visible="usernameFormVisible"
        :loading="confirmLoading"
        :model="usernameFormModel"
        @cancel="handleUsernameCancel"
        @ok="handleUsernameSave"
      />
      <player-game-version-form
        ref="versionForm"
        :visible="versionFormVisible"
        :loading="confirmLoading"
        :model="versionFormModel"
        @cancel="handleVersionCancel"
        @ok="handleVersionSave"
      />
      <player-bonus-date-form
        ref="bonusForm"
        :visible="bonusFormVisible"
        :loading="confirmLoading"
        :model="bonusFormModel"
        @cancel="handleBonusCancel"
        @ok="handleBonusSave"
      />
      <player-chara-slot-form
        ref="charaForm"
        :visible="charaFormVisible"
        :loading="confirmLoading"
        :model="charaFormModel"
        @cancel="handleCharaCancel"
        @ok="handleCharaSave"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  deletePlayer,
  getPlayerDetail,
  getPlayerExport,
  setPlayerBonusDate,
  setPlayerCharaSlot,
  setPlayerGameVersion, setPlayerNetMember, setPlayerUsername
} from '@/api/game/mai2'
import { SaveString } from '@/utils/download'
import PlayerGameVersionForm from '@/views/game/mai2/module/PlayerGameVersionForm'
import PlayerBonusDateForm from '@/views/game/mai2/module/PlayerBonusDateForm'
import PlayerCharaSlotForm from '@/views/game/mai2/module/PlayerCharaSlotForm'
import PlayerUsernameForm from '@/views/game/mai2/module/PlayerUsernameForm'
import { Mai2NetMember } from '@/model/mai2const'

export default {
  name: 'PlayerDetail',
  components: { PlayerCharaSlotForm, PlayerBonusDateForm, PlayerGameVersionForm, PlayerUsernameForm },
  data () {
    return {
      detail: null,
      id: this.$route.params.id,
      usernameFormVisible: false,
      usernameFormModel: null,
      versionFormVisible: false,
      versionFormModel: null,
      bonusFormVisible: false,
      bonusFormModel: null,
      charaFormVisible: false,
      charaFormModel: null,
      confirmLoading: false,
      currentMember: 'LOADING',
      netMemberType: Mai2NetMember
    }
  },
  mounted () {
    if (this.$route.params.id) {
      getPlayerDetail({ id: this.$route.params.id }).then(res => {
        this.detail = res
        this.currentMember = Mai2NetMember[this.detail.user.isNetMember].label
      })
    }
  },
  methods: {
    exportPlayer () {
      this.$auth()
      getPlayerExport({ playerId: this.id }).then(res => {
        SaveString(JSON.stringify(res), `mai2user-${this.id}.json`, 'application/json')
      })
    },
    confirmDelete () {
      deletePlayer({ playerId: this.id }).then(res => {
        this.$router.replace('/game/mai2/player')
      })
    },
    /**
     * Version form
     */
    handleEditVersion () {
      this.$refs.versionForm.form.resetFields()
      this.versionFormModel = {
        playerId: this.$route.params.id,
        lastRomVersion: this.detail.user.lastRomVersion,
        lastDataVersion: this.detail.user.lastDataVersion
      }
      this.versionFormVisible = true
    },
    handleVersionCancel () {
      this.versionFormVisible = false
    },
    handleVersionSave () {
      const form = this.$refs.versionForm.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.isValid = values.isValid === 'true'
          setPlayerGameVersion(values).then((resp) => {
            this.versionFormVisible = false
            form.resetFields()
            this.$message.success('保存成功')
            this.detail.user = resp
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * username form
     */

    handleEditUsername () {
      this.$refs.usernameForm.form.resetFields()
      this.usernameForm = {
        playerId: this.$route.params.id,
        userName: this.detail.user.userName
      }
      this.usernameFormVisible = true
    },
    handleUsernameCancel () {
      this.usernameFormVisible = false
    },
    handleUsernameSave () {
      const form = this.$refs.usernameForm.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.isValid = values.isValid === 'true'
          setPlayerUsername(values).then((resp) => {
            this.usernameFormVisible = false
            form.resetFields()
            this.$message.success('保存成功')
            this.detail.user = resp
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * bonus form
     */
    handleEditBonus () {
      this.$refs.bonusForm.form.resetFields()
      this.bonusFormModel = {
        playerId: this.$route.params.id,
        dailyBonusDate: this.detail.user.dailyBonusDate,
        dailyCourseBonusDate: this.detail.user.dailyCourseBonusDate
      }
      this.bonusFormVisible = true
    },
    handleBonusCancel () {
      this.bonusFormVisible = false
    },
    handleBonusSave () {
      const form = this.$refs.bonusForm.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.isValid = values.isValid === 'true'
          setPlayerBonusDate(values).then((resp) => {
            this.bonusFormVisible = false
            form.resetFields()
            this.$message.success('保存成功')
            this.detail.user = resp
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEditChara () {
      this.$refs.charaForm.form.resetFields()
      this.charaFormModel = {
        playerId: this.$route.params.id,
        chara1: this.detail.user.charaSlot[0],
        chara2: this.detail.user.charaSlot[1],
        chara3: this.detail.user.charaSlot[2],
        chara4: this.detail.user.charaSlot[3],
        chara5: this.detail.user.charaSlot[4]
      }
      this.charaFormVisible = true
    },
    handleCharaCancel () {
      this.charaFormVisible = false
    },
    handleCharaSave () {
      const form = this.$refs.charaForm.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.isValid = values.isValid === 'true'
          setPlayerCharaSlot(values).then((resp) => {
            this.charaFormVisible = false
            form.resetFields()
            this.$message.success('保存成功')
            this.detail.user = resp
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    setMember (number) {
      setPlayerNetMember({ playerId: this.$route.params.id, netMember: number }).then((resp) => {
        this.$message.success('保存成功')
        this.detail.user = resp
      }).finally(() => {
        this.confirmLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
